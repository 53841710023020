import React, { useEffect, useState } from "react";

const PanVerification = (props) => {
  const toastConfig = {
    autoClose: 3000,
    closeButton: false,
    hideProgressBar: true,
  };
  const [pandetails, setPandetails] = useState({});
  const [showPanVerify, setShowPanVerify] = useState(false);
  const [showPanDetails, setShowPanDetails] = useState(false);
  const [isPanDisabled, setIsPanDisabled] = useState(true);
  const isPanVerified =
    props?.editdata?.customerPanCardInfo === null ? false : true;

  const onChangePan = (e) => {
    let value = e.target.value;
    if (value.length > 0) {
      setIsPanDisabled(false);
    } else {
      setIsPanDisabled(true);
    }
  };
  // PAN
  const onPanCheck = (e, data) => {
    e.preventDefault();
    console.log({ onPanCheck: data });
    setShowPanVerify(true);
    toast.success("OTP Sent Successfully", toastConfig);
  };

  const onPanVerify = (e, data) => {
    e.preventDefault();
    console.log({ onPanVerify: data });
    let payload = {
      pan: data.panNumber,
    };
    console.log({ payload });
    props.apis.apiGetCustomerPanDetails(payload).then((res) => {
      console.log({ apiGetCustomerPanDetails: res });
      props.store("user", {
        ...props?.$store?.user,
        customerPanCardInfo: {
          name: res?.name,
          number: res?.number,
        },
      });
      setPandetails(res);
      setShowPanDetails(true);
    });
    // setPandetails({name: "Ravindra M"});
    // setShowPanDetails(true);
  };

  // TOGGLE FUNCTION
  const handlePanDetailsShow = () => {
    setShowPanDetails(true);
  };
  const handlePanDetailsClose = () => {
    setShowPanDetails(false);
  };

  const onPanSubmit = (e) => {
    e.preventDefault();
    if (pandetails) {
      let payload = {
        ...pandetails,
        aadhaarNum: props?.editdata?.customerAadharCardInfo?.aadhaar_number,
      };

      props.apis.apiVerifyCustomerPanDetails(payload).then((res) => {
        console.log({ apiVerifyCustomerPanDetails: res });
        toast.success("PAN Verified Successfully", toastConfig);
        setShowPanDetails(false);
        setPandetails({});
        props.handleSelect("addressDetails");
      });
    }
  };
  useEffect(() => {
    console.log({ isPanVerified });
  }, []);

  return (
    <>
      {!isPanVerified && (
        <AppForm onSubmit={onPanVerify}>
          <div className="col-12 col-md-8 mb-3">
            <label htmlFor="mobileNumber">PAN No.</label>
            <input
              type="text"
              label="PAN No"
              className="form-control"
              onChange={(e) => onChangePan(e)}
              name="panNumber"
            />
          </div>
          <div className="col-12 col-md-3 mb-3 d-flex align-items-end">
            <button
              type="submit"
              className="btn btn-primary w-100"
              disabled={isPanDisabled}
            >
              Verify
            </button>
          </div>
          {/* <div className="nav_container mt-4">
            <button
              className="btn btn-primary-lite me-3"
              onClick={() => props.handleSelect("addressDetails")}
            >
              Skip
            </button>
          </div> */}
        </AppForm>
      )}
      {/* PAN DETAILS CONTAINER */}

      {isPanVerified ? (
        <div
          className={Object.className({
            profile_details_container: true,
          })}
        >
          <div className="profile_detail mb-3">
            <p className="m-0">Name as per PAN</p>
            <h4>{props?.editdata?.customerPanCardInfo?.name || "-"}</h4>
          </div>
          <div className="profile_detail mb-3">
            <p className="m-0">PAN Number</p>
            <h4>{props?.editdata?.customerPanCardInfo?.number || "-"}</h4>
          </div>
          <div className="nav_container">
            <button
              className="btn btn-primary"
              onClick={(...args) => props.handleSelect("addressDetails")}
            >
              Next
            </button>
          </div>
        </div>
      ) : (
        <div
          className={Object.className({
            profile_details_container: true,
            hidden: !showPanDetails || isPanVerified,
          })}
        >
          <div className="profile_detail mb-3">
            <p className="m-0">Name as per PAN</p>
            <h4>{pandetails?.name?.toTitleCase()}</h4>
          </div>
          <div className="profile_detail mb-3">
            <p className="m-0">PAN No.</p>
            <h4>{pandetails?.number || "-"}</h4>
          </div>
          <div className="profile_detail mb-3 d-none">
            <p className="m-0">Date of birth as per PAN</p>
            <h4></h4>
          </div>
          <button
            className="btn btn-primary"
            onClick={(...args) => onPanSubmit(...args)}
          >
            Save & Submit
          </button>
        </div>
      )}
    </>
  );
};

export default constore(PanVerification);
