import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const FinalReview = (props) => {
  const isAdmin = props?.$store?.persona === _enum.ROUTE_PERSONAS.admin;
  var isReadonly = props.urlparams.params.readonly === "true" ? true : false;
  var tabStatus = props.urlparams.params.status;
  const frompath = props?.urlparams?.params?.frompath;
  const modalFields = [
    {
      name: "customerName",
      label: "Applicant Name",
      type: "text",
      readOnly: true,
    },
    {
      name: "companyname",
      label: "Company Name",
      type: "text",
      readOnly: true,
    },
    {
      name: "companyCategory",
      label: "Company Category",
      type: "text",
      readOnly: true,
    },
    {
      name: "salaryCreditDate",
      label: "Salary Credit Date",
      type: "date",
      dateClassName: "fakedisabled",
    },
    { name: "doj", label: "DOJ", type: "date", readOnly: true },
    {
      name: "overallJobStability",
      label: "Overall Job Stability",
      type: "select",
      options: _enum.PRESENCE_YEAR_KEY,
      readOnly: isAdmin ? true : isReadonly ? true : false,
      required: true,
    },
    {
      name: "applicantDesignation",
      label: "Applicant Designation",
      readOnly: true,
    },
    { name: "cibilScore", label: "Cibil Score", type: "text", readOnly: true },
    {
      name: "residenceStatus",
      label: "Residence Type",
      type: "text",
      readOnly: true,
    },
    {
      name: "residanceStablity",
      label: "Residence Stability",
      type: "select",
      options: _enum.PRESENCE_YEAR_KEY,
      readOnly: isAdmin ? true : isReadonly ? true : false,
      required: true,
    },
    {
      name: "inCityPresence",
      label: "In City Presence",
      type: "select",
      options: _enum.PRESENCE_YEAR_KEY,
      readOnly: isAdmin ? true : isReadonly ? true : false,
      required: true,
    },
    {
      name: "overallCityPresence",
      label: "Overall City Presence",
      type: "select",
      options: _enum.PRESENCE_YEAR_KEY,
      readOnly: isAdmin ? true : isReadonly ? true : false,
      required: true,
    },
    {
      name: "appliedLoanAmount",
      label: "Applied Loan Amount",
      type: "text",
      readOnly: true,
    },
    {
      name: "appliedTenure",
      label: "Applied Tenure",
      type: "text",
      readOnly: true,
    },
    {
      name: "monthlyNetTakeSalary",
      label: "Net Salary",
      type: "number",
      readOnly: true,
    },
    {
      name: "currentObligation",
      label: "Current Obligations",
      type: "number",
      readOnly: true,
    },
    // {
    //   name: "CreditCardOutstanding",
    //   label: "Credit Card Outstanding",
    //   type: "text",
    //   readOnly: true,
    // },
    {
      name: "avgMonthlyBalance",
      label: "Avg Bank Balance",
      type: "text",
      readOnly: true,
    },
    {
      name: "sanctionedIRR",
      label: "ROI Offered",
      type: "text",
      readOnly: true,
      defaultValue: "1 % per day",
    },
    {
      name: "eligibleLoanAmt",
      label: "Eligible Loan Amount",
      type: "number",
      required: true,
      readOnly: isAdmin ? true : isReadonly ? true : false,
    },
    {
      name: "sanctionedLoanAmt",
      label: "Final Loan Amount Approved",
      type: "number",
      required: true,
      // onChange: (e) => {
      //   handleLoanAmountChange(e);
      // },
      readOnly: isAdmin ? true : isReadonly ? true : false,
    },
    {
      name: "sanctionedTenure",
      label: "Final Tenure",
      type: "number",
      required: true,
      readOnly: isAdmin ? true : isReadonly ? true : false,
    },
    {
      name: "sanctionedRepayType",
      label: "Repayment Type",
      type: "select",
      options: _enum.REPAYMENT_TYPE_KEY,
      onChange: (...args) => handleRepaymentChange(...args),
      readOnly: isAdmin ? true : isReadonly ? true : false,
    },
    {
      name: "sanctionedNoOfMonth",
      label: "No. Of Months",
      type: "number",
      // options: _enum.NO_OF_MONTHS,
      readOnly: isAdmin ? true : isReadonly ? true : false,
    },
    {
      name: "sanctionedEMIAmt",
      label: "Final EMI",
      type: "number",
      readOnly: isAdmin ? true : isReadonly ? true : false,
    },
    {
      name: "sanctionedEMIDate",
      label: "EMI Date",
      type: "date",
      required: true,
      readOnly: isAdmin ? true : isReadonly ? true : false,
    },
    {
      name: "isDocsRequired",
      label: "Additional Document Required",
      type: "select",
      options: _enum.YES_OR_NO_BOOLEAN,
      onChange: (...args) => handleIsDocsRequiredChange(...args),
      readOnly: isAdmin ? true : isReadonly ? true : false,
    },
    {
      name: "additionalDocsRequested",
      label: "Document Type",
      type: "select",
      options: _enum.DOC_TYPES_NEW_WITH_OTHERS,
      multi: true,
      onChange: (...args) => handleAdditionalDocsChange(...args),
      readOnly: isAdmin ? true : isReadonly ? true : false,
    },
    {
      name: "documentName",
      label: "Document Name",
      type: "text",
      columnClass: "d-none",
      readOnly: isAdmin ? true : isReadonly ? true : false,
    },
    {
      name: "sanctionedPctPF",
      label: "Processing Fees %",
      type: "select",
      options: _enum.PROCESSING_FEE_PERCENTAGES,
      onChange: (...args) => handleProcessingFeePercentage(...args),
      required: true,
      readOnly: isAdmin ? true : isReadonly ? true : false,
    },
    {
      name: "sanctionedPF",
      label: "Processing Fees",
      type: "number",
      step: ".01",
      readOnly: true,
    },
    {
      name: "channel",
      label: "Sourcing Channel",
      type: "select",
      options: _enum.SOURCING_CHANNELS,
      readOnly: isAdmin ? true : isReadonly ? true : false,
    },
    {
      name: "netDisbursementAmt",
      label: "Net Disbursement Amt", // sanctionedLoanAmt - Processing Fee
      type: "number",
      readOnly: true,
    },
    {
      name: "customerTVRComment",
      label: "Customer TVR Comment",
      type: "text",
      customcolspan: 12,
      readOnly: isAdmin ? true : isReadonly ? true : false,
    },
  ];
  const [fields, setFields] = useState(modalFields);
  const [reviewdata, setReviewdata] = useState({});

  const handleLoanAmountChange = (e) => {
    let loanAmount = e.target.value;
    let processingFee = loanAmount * 0.1;
    let processignFeeField = fields.find(
      (field) => field.name === "sanctionedPF"
    );
    processignFeeField.defaultValue = processingFee?.toFixed(2);
    let netDisbursementAmtField = fields.find(
      (field) => field.name === "netDisbursementAmt"
    );
    netDisbursementAmtField.defaultValue = loanAmount - processingFee;
    setFields([...fields]);
  };

  const showInputs = (inputnames) => {
    if (inputnames.length > 0) {
      inputnames.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          element.classList.remove("d-none");
          let input = element?.querySelector("input");
          input?.removeAttribute("required");

          if (!input.hasAttribute("required")) {
            input.setAttribute("required", true);
          }
        }
      });
    }
  };

  const hideInputs = (inputnames) => {
    if (inputnames.length > 0) {
      inputnames.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          element.classList.add("d-none");
          element?.querySelector("input")?.removeAttribute("required");
        }
      });
    }
  };

  const handleRepaymentChange = (args) => {
    let repaymentType = args[0]?.key;
    let inputs = [
      "column-sanctionedNoOfMonth",
      "column-sanctionedEMIAmt",
      "column-sanctionedEMIDate",
    ];
    if (repaymentType === "BULLET") {
      hideInputs(inputs);
    } else {
      showInputs(inputs);
    }
  };

  const handleIsDocsRequiredChange = (args) => {
    let isDocsRequiredKey = args[0]?.key;

    let inputs = ["column-additionalDocsRequested", "column-documentName"];
    if (isDocsRequiredKey === false) {
      hideInputs(inputs);
    } else {
      showInputs(inputs);
    }
  };

  const handleAdditionalDocsChange = (args) => {
    // var additionalDocsInput = document.querySelector(
    //   "[name='appinput-additionalDocsRequested'].form-group"
    // );
    // var additionalDocsSelect = additionalDocsInput?.vnode.refs.select;
    // console.log(additionalDocsSelect, additionalDocsSelect?.state);

    var documentNameInput = document.querySelector("#column-documentName");

    let additionalDocsField = modalFields.find(
      (o) => o.name == "additionalDocsRequested"
    );
    const hasOthers = args?.some((obj) => obj.key === "OTHERS");

    if (hasOthers) {
      documentNameInput.classList.remove("d-none");
      additionalDocsField.multi = false;
    } else {
      documentNameInput.classList.add("d-none");
      additionalDocsField.multi = true;
    }
    setFields([...fields]);
  };

  const percentageCalc = (percent, total) => {
    return ((percent / 100) * total).toFixed(2);
  };

  const handleProcessingFeePercentage = (args) => {
    let value = args[0]?.key;
    let loanAmountValue = document.querySelector(
      '[name="sanctionedLoanAmt"]'
    )?.value;

    if (value) {
      let processingFee = percentageCalc(value, loanAmountValue);

      console.log(`${value}% of ${loanAmountValue} is ${processingFee}`);

      let processignFeeField = fields.find(
        (field) => field.name === "sanctionedPF"
      );
      processignFeeField.defaultValue = processingFee;

      let netDisbursementAmtField = fields.find(
        (field) => field.name === "netDisbursementAmt"
      );
      netDisbursementAmtField.defaultValue = loanAmountValue - processingFee;
      setFields([...fields]);
    }
  };

  const getFinalReview = () => {
    props.apis
      .apiUnderWriterGetApplicationReview({
        applicationId: props.urlparams.params.id,
      })
      .then((res) => {
        console.log({ res });
        if (res) {
          let filteredData = {
            customerName: res?.customerName,
            companyname: res?.ApplicationSalaryInfo[0]?.companyname,
            companyCategory: res?.ApplicationSalaryInfo[0]?.companyCategory,
            doj: res?.ApplicationSalaryInfo[0]?.doj,
            cibilScore:
              res?.applicationEquifaxRetailAccountsSummary?.cibilScore,
            applicantDesignation:
              res?.ApplicationSalaryInfo[0]?.applicantJobProfile,
            salaryCreditDate: res?.ApplicationSalaryInfo[0]?.salaryCreditDate,
            avgMonthlyBalance: res?.ApplicationSalaryInfo[0]?.avgMonthlyBalance,
            netMonthlyIncome: res?.ApplicationSalaryInfo[0]?.netMonthlyIncome,
            monthlyNetTakeSalary:
              res?.ApplicationSalaryInfo[0]?.monthlyNetTakeSalary,
            appliedLoanAmount: res?.loanAmount,
            appliedTenure: res?.duration,
            currentObligation: res?.currentObligation,
            sanctionedPF: res?.loanAmount * 0.1,
            ...Object.only(res, [
              "residanceStablity",
              "inCityPresence",
              "overallCityPresence",
              "eligibleLoanAmt",
              "sanctionedLoanAmt",
              "sanctionedTenure",
              "sanctionedPctPF",
              "sanctionedRepayType",
              "sanctionedIRR",
              "overallJobStability",
              "sanctionedNoOfMonth",
              "sanctionedEMIAmt",
              "sanctionedEMIDate",
              "monthlyObligation",
              "customerTVRComment",
              "netDisbursementAmt",
              "channel",
            ]),
            residenceStatus: res?.ApplicationAddressInfo?.[0]?.residenceStatus,
            additionalDocsRequested: res?.additionalDocsRequested,
          };
          setReviewdata(filteredData);

          let updatedFields = fields?.map((field) => {
            field.defaultValue = field.defaultValue;
            if (![undefined].includes(filteredData[field.name])) {
              if (field.type == "date") {
                field.defaultValue =
                  filteredData[field.name] && dayjs(filteredData[field.name]);
              } else {
                field.defaultValue = filteredData[field.name];
              }
            }
            if (field.name == "isDocsRequired") {
              let value = res?.additionalDocsRequested;
              if (Array.isArray(value) && value.length === 0) {
                field.defaultValue = false;
              } else {
                field.defaultValue = true;
              }
            }
            return field;
          });
          // processing fee will be 10% of the applied loan amount
          let amount = res?.loanAmount * 0.1;
          let processignFeeField = updatedFields.find(
            (field) => field.name === "sanctionedPF"
          );
          processignFeeField.defaultValue = amount;
          console.log({ filteredData, updatedFields });

          setFields([...updatedFields]);
        }
      });
  };

  // const updateDefaults = (arr, applicationData) => {
  //   return arr.map((o) => {
  //     let defaultValue = applicationData[o.name] || o.defaultValue || undefined;
  //     if (o.type == "select") {
  //       if (o.getOptions instanceof Function && o.options.length == 0) {
  //         o.options = o.getOptions(applicationData);
  //       }
  //       if (
  //         typeof defaultValue == "string" &&
  //         defaultValue.split(",").length > 1
  //       ) {
  //         defaultValue = defaultValue
  //           .split(",")
  //           .map((key) => o.options.find((o) => o.key == key));
  //       } else if (o.multiple) {
  //         defaultValue = o.options?.filter((o) =>
  //           defaultValue?.includes(o.key)
  //         );
  //       } else {
  //         defaultValue = o.options?.find((o) => o.key == defaultValue);
  //       }
  //     }
  //     return {
  //       ...o,
  //       defaultValue,
  //     };
  //   });
  // };

  const handleRejectReview = (e) => {
    e.preventDefault();
    swal2
      .fire({
        title:
          "Are you sure you want to reject the application?\n Please provide your remarks",
        icon: "info",
        iconColor: "#DE3232",
        input: "text",
        showCancelButton: true,
        confirmButtonColor: "#DE3232",
        cancelButtonColor: "##D6DBE7",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then((result) => {
        if (result.isConfirmed) {
          let payload = {
            rejectedReason: result.value,
          };
          props.apis
            .apiUnderWriterRejectApplication(payload, {
              applicationId: props.urlparams.params.id,
            })
            .then((res) => {
              swal2.fire({
                title: "Application Rejected",
                icon: "success",
                iconColor: "#FF4D4D",
                showCancelButton: false,
                timer: 3000,
              });
              navigate(`/app/loanapplications?activetab=${tabStatus}`);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
  };

  const handleAcceptReview = (e, data) => {
    e.preventDefault();
    let payload =
      data.sanctionedRepayType === "BULLET"
        ? Object.only(data, [
            "residanceStablity",
            "inCityPresence",
            "overallCityPresence",
            "eligibleLoanAmt",
            "sanctionedLoanAmt",
            "sanctionedTenure",
            "sanctionedRepayType",
            "sanctionedIRR",
            "sanctionedPF",
            "sanctionedPctPF",
            "overallJobStability",
            // "monthlyObligation",
            "customerTVRComment",
            "netDisbursementAmt",
            "additionalDocsRequested",
            "channel",
          ])
        : Object.only(data, [
            "residanceStablity",
            "inCityPresence",
            "overallCityPresence",
            "eligibleLoanAmt",
            "sanctionedLoanAmt",
            "sanctionedTenure",
            "sanctionedRepayType",
            "sanctionedNoOfMonth",
            "sanctionedEMIAmt",
            "sanctionedEMIDate",
            "sanctionedIRR",
            "sanctionedPF",
            "sanctionedPctPF",
            "overallJobStability",
            "customerTVRComment",
            "netDisbursementAmt",
            "additionalDocsRequested",
            "channel",
          ]);
    for (let key in payload) {
      if (
        key.endsWith("Amt") ||
        key.endsWith("Tenure") ||
        key.endsWith("NoOfMonth")
      ) {
        payload[key] = Number(payload[key]);
      }
    }
    let isrequiredInput = document.querySelector(
      "[name='appinput-isDocsRequired'].form-group"
    );
    let isrequiredSelect = isrequiredInput.vnode.refs.select;

    let requiredKey = isrequiredSelect?.state?.values[0]?.key;
    console.log({ requiredKey });

    if (requiredKey == true) {
      payload.additionalDocsRequested =
        payload.additionalDocsRequested.split(",");
    } else {
      payload.additionalDocsRequested = [];
    }
    payload = {
      ...payload,
      sanctionedIRR: 1,
      sanctionedPF: Number(data?.sanctionedPF),
      sanctionedPctPF: Number(data?.sanctionedPctPF),
      sanctionedEMIDate: new Date(data?.sanctionedEMIDate),
    };
    console.log({ payload });

    props.apis
      .apiUnderWriterSanctionApplication(payload, {
        applicationId: props.urlparams.params.id,
      })
      .then((res) => {
        swal2.fire({
          title: "Final Review Submitted",
          icon: "success",
          iconColor: "#FF4D4D",
          showCancelButton: false,
          timer: 3000,
        });
        navigate(`/app/loanapplications?activetab=${tabStatus}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFinalReview();
  }, []);

  const getBankLink = () => {
    let link;
    if (frompath === "loanapplications") {
      link = `/app/loanapplications?activetab=${tabStatus}`;
    }
    if (frompath === "dupecheck") {
      link = `/app/dupecheck`;
    }
    return link;
  };

  props.$setNode({
    props,
    reviewdata,
    modalFields,
  });

  return (
    <div className="card p-4">
      <AppForm className="mx-auto" onSubmit={handleAcceptReview}>
        {fields.map((field, i) => (
          <div
            className={`col-12 col-md-${field?.customcolspan || 4} ${
              field?.columnClass || ""
            }`}
            id={`column-${field?.name}`}
          >
            <AppInput
              {...field}
              // {...(tabStatus !== "inprogress"
              //   ? {
              //       outerclass:
              //         "col-xl-6 mb-3 w-100 d-flex flex-column pe-none fakedisabled",
              //     }
              //   : { outerclass: "col-xl-6 mb-3 w-100 d-flex flex-column" })}
            ></AppInput>
          </div>
        ))}

        {isReadonly ? (
          <div className="nav_container mt-5">
            <button
              type="button"
              onClick={(...args) => props.changeTab(...args, "queries")}
              className="btn btn-primary-lite me-3"
            >
              Back
            </button>
            {tabStatus === "sanctioned" ? (
              <button
                type="button"
                onClick={(...args) =>
                  props.changeTab(...args, "postsanctioneddocs")
                }
                className="btn btn-primary"
              >
                Next
              </button>
            ) : (
              <Link to={getBankLink()} className="btn btn-primary">
                Close
              </Link>
            )}
          </div>
        ) : (
          <div className="nav_container mt-5">
            <Link to={getBankLink()} className="btn btn-primary-lite me-3">
              Cancel
            </Link>
            <button
              className="btn btn-primary-lite me-3"
              type="button"
              onClick={handleRejectReview}
            >
              Reject
            </button>
            <button type="submit" className="btn btn-primary">
              Approve
            </button>
          </div>
        )}
      </AppForm>
    </div>
  );
};

export default constore(FinalReview);
